import React from "react";
import classanmes from "classnames";
import SamanLoira from "@/icons/logo.svg";
import SL from "@/icons/SL.svg";

export interface Props {
  className?: string;
  type?: "full" | "sm";
}

const Logo = ({ className, type = "full" }: Props) => {
  const classNames = classanmes("logo", className);

  if (type === "sm") {
    return <SL className={classNames} />;
  }

  return <SamanLoira className={classNames} />;
};

export default Logo;
