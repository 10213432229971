import React, { useRef, createContext, useState, useEffect, useMemo, CSSProperties, useCallback } from "react";
import { IParallax, Parallax, ParallaxProps } from "@react-spring/parallax";
import classnames from "classnames";

type Context = number;

export const ParallaxContext = createContext<Context>(0);

type Common = { header: React.ReactNode; hasTopSpacing?: boolean };
type Scroll = { children: React.ReactNode };
type Parallax = {
  pages?: ParallaxProps["pages"];
} & Omit<ParallaxProps, "pages">;

export type Props = Common & (({ scroll?: "default" } & Scroll) | ({ scroll: "parallax" } & Parallax));

const Layout = (props: Props) => {
  if (props.scroll === "parallax") {
    return <ParallaxLayout {...props} />;
  }

  return <ScrollLayout {...props} />;
};

const ScrollLayout = ({ header, hasTopSpacing, children }: Common & Scroll) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setValue(window.pageYOffset / window.innerHeight);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <ParallaxContext.Provider value={value}>
      {header}
      <div
        className={classnames({
          "pt-32": hasTopSpacing,
        })}
      >
        {children}
      </div>
    </ParallaxContext.Provider>
  );
};

const ParallaxLayout = ({ header, children, pages: defaultPages, ...parallaxProps }: Common & Parallax) => {
  const parallax = useRef<IParallax>(null);
  const [value, setValue] = useState(0);
  const pages = useMemo(() => {
    if (defaultPages) {
      return defaultPages;
    }

    return React.Children.toArray(children).filter(child => {
      if (!React.isValidElement<SectionProps>(child)) {
        return;
      }

      return child.type === LayoutSection;
    }).length;
  }, [children, defaultPages]);
  const onScroll = useCallback(() => {
    const currentPosition = (parallax.current?.current || 1) / (parallax.current?.space || 1);
    setValue(currentPosition);
  }, []);

  useEffect(() => {
    if (!parallax.current) return;
  }, [parallax]);

  return (
    <ParallaxContext.Provider value={value}>
      {header}
      <Parallax pages={pages} ref={parallax} onScrollCapture={onScroll} {...parallaxProps}>
        {children}
      </Parallax>
    </ParallaxContext.Provider>
  );
};

export const SectionContext = React.createContext<number>(0);

type SectionProps = {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  span?: number;
  theme?: "white" | "alabaster" | "mischka" | "athens-gray";
};

const LayoutSection = ({ children, className, style, span = 1, theme = "white" }: SectionProps) => {
  const section = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (section.current) {
      setValue(section.current.offsetTop / window.innerHeight);
    }
  }, [section]);

  return (
    <div
      ref={section}
      className={classnames(
        className,
        "flex flex-col justify-center items-center min-h-screen relative my-10 md:my-0 first:mt-0 overflow-hidden",
        `bg-${theme}`
      )}
      style={{
        ...style,
        minHeight: `${span * 100}vh`,
      }}
    >
      <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
    </div>
  );
};

Layout.Section = LayoutSection;

export default Layout;
