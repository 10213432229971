import React, { useContext } from "react";
import Button, { AnchorButton } from "@/components/Button";
import MicroCopy from "@/components/MicroCopy";
import { StoreContext } from "@/components/StoreContext";

import Trash from "@/icons/trash.svg";

interface Props {
  children: React.ReactNode;
}

const ProductsTable = ({ children }: Props) => {
  const { close, checkout } = useContext(StoreContext);

  return (
    <div>
      {children}
      <div className="flex justify-between">
        <span className="uppercase">Total</span>
        {/* @ts-expect-error: For some reason TS doesn't know about this. */}
        <span className="font-bold">&euro; {checkout?.subtotalPrice.amount}</span>
      </div>
      <div className="flex justify-between mt-8">
        <Button rank="secondary" onClick={close}>
          <MicroCopy path="basket.continue-shopping">Continue Shopping</MicroCopy>
        </Button>
        <AnchorButton href={checkout?.webUrl}>
          <MicroCopy path="basket.checkout">Checkout</MicroCopy>
        </AnchorButton>
      </div>
    </div>
  );
};

interface ItemProps {
  imageSource: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  price: string;
  onRemove: () => void | Promise<void>;
}

const ProductsTableItem = ({ imageSource, title, subtitle, price, onRemove }: ItemProps) => {
  return (
    <div className="flex pb-4 mb-4 border-b font-subtitile border-silver">
      <div className="mr-2 overflow-hidden w-14 h-14">
        <img className="object-cover object-top" src={imageSource} alt={title?.toString() || "Product image"} />
      </div>
      <div className="flex-grow">
        <div className="flex justify-between">
          <span className="block uppercase">{title}</span>
          <button
            onClick={onRemove}
            className="w-6 h-6 p-1 text-dove-gray bg-alabaster hover:bg-black hover:text-white"
          >
            <Trash className="empty-icon" />
          </button>
        </div>
        <div className="flex justify-between">
          <span className="text-dove-gray">{subtitle}</span>
          <span>&euro; {price}</span>
        </div>
      </div>
    </div>
  );
};

ProductsTable.Item = ProductsTableItem;

export default ProductsTable;
