import React, { useContext, useEffect, useMemo } from "react";
import MicroCopy from "@/components/MicroCopy";
import { StoreContext } from "@/components/StoreContext";
import Heading from "@/components/Heading";
import classNames from "classnames";
import Cockpit from "@/components/Cockpit";
import ProductsTable from "../ProductsTable";

const BasketDrawer = () => {
  const { isOpen, close, checkout, removeLineItem } = useContext(StoreContext);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  const products = useMemo(() => {
    if (!checkout) {
      return [];
    }

    // @ts-expect-error Something wrong with the version I believe, variant
    // does exist in `lineItem`.
    return checkout.lineItems.map(({ title, variant, productId, ...rest }) => {
      return {
        title,
        image: variant.image.src,
        price: variant.priceV2,
        variant,
        id: productId,
        product: rest,
      };
    });
  }, [checkout]);

  return (
    <>
      <div
        className={classNames(
          "fixed bg-white top-0 right-0 md:w-1/3 min-w-96 h-full w-full overflow-auto p-8 z-50 transform translate-x-0 duration-300 transition-transform text-black",
          {
            "translate-x-full": !isOpen,
          }
        )}
      >
        <div className="mb-10 border-b border-dove-gray">
          <Heading component="h2" level={2}>
            <MicroCopy path="basket.title">Basket</MicroCopy>
          </Heading>
          {isOpen && <Cockpit onCloseClick={close} style={{ marginRight: 32 }} />}
        </div>
        {products.length > 0 ? (
          <ProductsTable>
            {products.map((product, index) => {
              const quantity = product.product.quantity > 1 ? ` (${product.product.quantity})` : "";

              return (
                <ProductsTable.Item
                  key={`${index}-${product.title}`}
                  title={product.title}
                  subtitle={product.variant.title + quantity}
                  price={product.price.amount}
                  imageSource={product.image}
                  onRemove={() => {
                    if (!checkout || !removeLineItem) {
                      return;
                    }

                    removeLineItem(checkout?.id, product.product.id);
                  }}
                />
              );
            })}
          </ProductsTable>
        ) : (
          <MicroCopy path="basket.empty">Your basket is empty</MicroCopy>
        )}
      </div>
      <div
        className={classNames("fixed top-0 bottom-0 left-0 right-0 z-40 bg-black opacity-0 transition-opacity", {
          "opacity-50": isOpen,
          "pointer-events-none": !isOpen,
        })}
      />
    </>
  );
};

export default BasketDrawer;
