import React, { CSSProperties } from "react";
import Button from "../Button";
import X from "@/icons/x.svg";
import { useSpring } from "@react-spring/core";
import { animated } from "@react-spring/web";
import classNames from "classnames";

interface Props {
  onCloseClick: () => void;
  className?: string;
  style?: CSSProperties;
  size?: "default" | "small";
}

const Cockpit = ({ onCloseClick, className, style: additionalStyle, size = "default" }: Props) => {
  const style = useSpring({ from: { top: -100 }, to: { top: 0 }, delay: 500 });

  return (
    <>
      <animated.div
        className={classNames("absolute top-0 right-0 z-10 mt-5 mr-5 md:mt-12 md:mr-32", className)}
        style={{ ...style, ...additionalStyle }}
      >
        <Button
          className={classNames({
            "w-12 h-12": size === "default",
            "w-6 h-6 p-2": size === "small",
          })}
          onClick={() => {
            onCloseClick();
          }}
          shape="round"
        >
          <X />
        </Button>
      </animated.div>
    </>
  );
};

export default Cockpit;
