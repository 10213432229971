import classnames from "classnames";
import React, { createContext, useContext } from "react";

type Level = 1 | 2 | 3 | 4 | 5 | 6;
type HeadingElement = `h${Level}`;

const HeadingContext = createContext<Level>(1);

export interface Props {
  className?: string;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  level?: Level;
  component?: React.ElementType;
}

const levelToFontsMap: Record<Level, string> = {
  1: "font-title text-5xl md:text-6xl",
  2: "font-title text-4xl md:text-5xl",
  3: "font-sans text-lg md:text-4xl bold",
  4: "font-sans uppercase text-base md:text-lg bold",
  5: "text-base uppercase bold",
  6: "text-base uppercase",
};

export const Container = ({ children }: { children: React.ReactNode }) => {
  const level = useContext(HeadingContext);

  // @ts-expect-error: TS is not smart-enough to understand this math.
  return <HeadingContext.Provider value={Math.min(level + 1, 6)}>{children}</HeadingContext.Provider>;
};

const Heading = ({ className, children, subtitle, level: defaultLevel, component }: Props) => {
  const level = useContext(HeadingContext);
  const MarkupComponent = component || (`h${level}` as HeadingElement);

  return (
    <MarkupComponent className={classnames("flex flex-col mb-4", className, levelToFontsMap[defaultLevel || level])}>
      <span className="mb-3 text-sm font-bold uppercase text-opacity-65 font-subtitile">{subtitle}</span>
      <span className="leading-snug">{children}</span>
    </MarkupComponent>
  );
};

export default Heading;
