import React from "react";
// @ts-expect-error Unfortunately this isn't typed.
import AniLink from "gatsby-plugin-transition-link/AniLink";
// import { Link } from "gatsby";

const Link = (props: any) => {
  return <AniLink cover bg="#ffffff" {...props} />;
};

export default Link;
