import Link from "@/components/Link";
import React from "react";
import MicroCopy from "@/components/MicroCopy";
import Logo from "@/components/Logo";
import InstagramIcon from "@/icons/instagram.svg";
import TwitterIcon from "@/icons/twitter.svg";

export type LinkItem = {
  to: string;
  label: React.ReactNode;
};

export interface Props {
  links: LinkItem[];
}

const NavigationItem = ({ to, label }: LinkItem) => {
  return (
    <li>
      <Link to={to} className="link link--inline">
        {label}
      </Link>
    </li>
  );
};

const Footer = ({ links }: Props) => {
  return (
    <footer className="w-full h-auto py-2 mt-auto md:py-8 md:h-80 bg-cod-gray text-alabaster">
      <div className="container flex flex-col h-full mx-auto">
        <div className="mb-10 grid grid-cols-6 md:grid-cols-12 gap-8">
          <div className="col-span-6 md:col-span-3">
            <Link to="/">
              <Logo type="sm" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
          <div className="col-span-2">
            <ul>
              {links.slice(0, Math.round(links.length / 2)).map(({ to, label }, index) => {
                return <NavigationItem key={`${to}-${index}`} to={to} label={label} />;
              })}
            </ul>
          </div>
          <div className="col-span-2">
            {links.length > 1 && (
              <ul>
                {links.slice(Math.round(links.length / 2)).map(({ to, label }, index) => {
                  return <NavigationItem key={`${to}-${index}`} to={to} label={label} />;
                })}
              </ul>
            )}
          </div>
          <div className="col-span-6 md:col-span-3">
            <form className="w-full max-w-sm">
              <legend>
                <span className="line-through">
                  <MicroCopy path="footer.newsletter.title">Sign up to the newsletter</MicroCopy>
                </span>{" "}
                Soon!
              </legend>
              <div className="flex items-center py-2 border-b border-boulder group">
                <input
                  className="w-full py-1 pr-2 mr-3 leading-tight text-white bg-transparent border-none appearance-none focus:outline-none focus"
                  type="email"
                  aria-label="email"
                  required
                  disabled
                />
                <button
                  className="flex-shrink-0 px-2 py-1 text-sm text-white cursor-not-allowed opacity-20 valid-sibling:opacity-100"
                  type="submit"
                  disabled
                >
                  <span className="sr-only">
                    <MicroCopy path="footer.newsletter.submit">Sign up</MicroCopy>
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    style={{
                      fill: "none",
                    }}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
          <div className="col-span-2">
            <span className="uppercase">
              <MicroCopy path="footer.social-media">Social Media</MicroCopy>
              <ul className="flex mt-4 space-x-4">
                <li>
                  <a href="https://www.instagram.com/saman_loira" target="_blank" rel="noreferrer">
                    <span className="sr-only">Instagram</span>
                    <InstagramIcon className="opacity-80 hover:opacity-100" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/LoiraSaman" target="_blank" rel="noreferrer">
                    <span className="sr-only">Twitter</span>
                    <TwitterIcon className="opacity-80 hover:opacity-100" />
                  </a>
                </li>
              </ul>
            </span>
          </div>
        </div>
        <div className="mt-auto text-xs font-bold uppercase text-boulder grid grid-cols-12 gap-8">
          <div className="col-span-7">
            <MicroCopy path="footer.copyright">
              2021 Saman Loira &copy;. All rights reserved. P.I. 02632600413
            </MicroCopy>
          </div>
          <div className="col-span-5">
            Developed with ❤ by{" "}
            <a href="https://github.com/alexcanessa" className="hover:text-white" target="_blank" rel="noreferrer">
              Alessandro Canessa
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
