import React, { useContext, useState } from "react";
import Link from "@/components/Link";
import classNames from "classnames";
import { Squeeze as Hamburger } from "hamburger-react";
import Logo from "@/components/Logo";
import { ParallaxContext } from "@/components/Layout";
import { StoreContext } from "@/components/StoreContext";
import { range } from "@/utils/interpolate";
import ShoppingBag from "@/icons/shopping-bag.svg";
import "@/styles/header.css";
import BasketDrawer from "../BasketDrawer";

export type NavigationItem = {
  label: React.ReactNode;
  to?: string;
};

export type UtilityItem = {
  label: React.ReactNode;
  to?: string;
  onClick?: () => void;
};

type Color = "transparent" | "black" | "white" | "transparent-black";
type Layout = "default" | "slim";

export interface Props {
  navigation?: NavigationItem[];
  utilities?: UtilityItem[];
  layout?: Layout;
}

const themeToClasses: Record<Color, Partial<Record<"wrapper" | "content", string>>> = {
  transparent: {
    wrapper: "text-white bg-transparent hover:bg-white hover:text-black",
    content: "group-hover:text-black",
  },
  black: {
    wrapper: "text-white bg-black",
  },
  white: {
    wrapper: "text-black bg-white",
    content: "text-black",
  },
  "transparent-black": {
    wrapper: "text-black bg-transparent hover:bg-white",
  },
};

const layoutToThemeMap: Record<Layout, (value: number) => Color> = {
  default: (value: number) => (value >= 1.4 ? "white" : "transparent"),
  slim: (value: number) => (value >= 0.2 ? "white" : "transparent-black"),
};

const layoutToRangeMap: Record<Layout, [number, number]> = {
  default: [1.3, 1.5],
  slim: [0.3, 0.4],
};

const Header = ({ navigation, utilities = [], layout = "default" }: Props) => {
  const value = useContext(ParallaxContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { checkout, open: openBasket } = useContext(StoreContext);

  const height = range(layoutToRangeMap[layout], [114, 85], value);
  const theme = layoutToThemeMap[layout](value);

  const items = checkout ? checkout.lineItems : [];
  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  return (
    <>
      <div
        style={{ height }}
        className={classNames(
          "fixed top-0 z-20 flex items-center justify-between space-x-10 w-screen p-4 md:px-8 overflow-hidden transition-colors duration-300",
          themeToClasses[theme].wrapper,
          {
            group: !isMenuOpen,
          }
        )}
      >
        <div className="flex-1">
          <div
            className={classNames("md:hidden relative z-30", {
              "text-white": isMenuOpen,
            })}
          >
            <Hamburger
              size={20}
              duration={0.25}
              label="Show menu"
              onToggle={toggled => {
                setIsMenuOpen(toggled);
              }}
            />
          </div>
          {navigation && (
            <ul
              className={classNames("navigation", {
                "navigation--open": isMenuOpen,
              })}
            >
              {navigation.map(({ label, to }, index) => {
                return (
                  <li key={`navigaton-item-${index}`}>
                    <Link
                      to={to || "/"}
                      className={classNames("link", {
                        [themeToClasses[theme].content || ""]: !isMenuOpen,
                        "text-white": isMenuOpen,
                      })}
                    >
                      {label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <Link to="/" className="mt-3 sm:self-start">
          <Logo />
          <span className="sr-only">Home</span>
        </Link>
        <div className="flex-1">
          <ul className="flex justify-end">
            {utilities
              .concat({
                label: (
                  <div className="relative">
                    <ShoppingBag style={{ fill: "none" }} />
                    <span className="sr-only">Open your basket</span>
                    {quantity > 0 && (
                      <span className="absolute left-1/2 bottom-1/2">
                        <div className="inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white rounded-full leading-4 bg-brick-red">
                          <span className="sr-only">with items:</span>
                          {quantity}
                        </div>
                      </span>
                    )}
                  </div>
                ),
                onClick: () => openBasket(),
              })
              .map(({ label, to, onClick }, index) => {
                const MarkupComponent = to ? Link : "button";
                const props = to ? { to } : { onClick };

                return (
                  <li key={`navigaton-item-${index}`}>
                    <MarkupComponent
                      {...props}
                      className={classNames("link", {
                        [themeToClasses[theme].content || ""]: !isMenuOpen,
                        "text-white": isMenuOpen,
                      })}
                    >
                      {label}
                    </MarkupComponent>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      <BasketDrawer />
    </>
  );
};

export default Header;
