import React from "react";
import classnames from "classnames";

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  rank?: "primary" | "secondary";
  shape?: "default" | "round";
}

const Button = ({ children, className, shape = "default", rank = "primary", ...rest }: Props) => {
  return (
    <button
      className={classnames(
        "text-sm rounded-full disabled:bg-silver disabled:text-white disabled:cursor-not-allowed",
        className,
        {
          "p-4": shape === "round",
          "py-2.5 px-5": shape === "default",
          "bg-cod-gray text-white hover:bg-white hover:text-cod-gray": rank === "primary",
          "bg-athens-gray text-cod-gray hover:text-white hover:bg-cod-gray": rank === "secondary",
        }
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export interface AnchorProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  rank?: "primary" | "secondary";
  shape?: "default" | "round";
}

export const AnchorButton = ({ children, className, shape = "default", rank = "primary", ...rest }: AnchorProps) => {
  return (
    <a
      className={classnames(
        "text-sm rounded-full disabled:bg-silver disabled:text-white disabled:cursor-not-allowed cursor-pointer",
        className,
        {
          "p-4": shape === "round",
          "py-2.5 px-5": shape === "default",
          "bg-cod-gray text-white hover:bg-white hover:text-cod-gray": rank === "primary",
          "bg-athens-gray text-cod-gray hover:text-white hover:bg-cod-gray": rank === "secondary",
        }
      )}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Button;
